<template>

  <div>
    <!-- Transport System Section Start -->
    <section class="transport-section transport-style-four pt-50">
      <div class="container">
        <div class="section-title text-center">

          <h2>Comment ça marche ?</h2>
        </div>

        <div class="row">
          <div
            class="col-lg-2 col-sm-6 wow fadeInUp"
            data-wow-duration="1s"
          >
            <div class="transport-card text-center">
              <div class="transport-text">

                <i class="icofont-login" /><br><br>

                <h6>Inscription et publication de l'offre  sur eBDFRET</h6>
                <!--  <p>Créer un Compte eBDFRET  gratuitement et facilement  </p><br><br><br><br>
-->
                <a href="service-details.html" />
              </div>
            </div>
          </div>
          <div
            class="col-lg-2 col-sm-6 wow fadeInUp"
            data-wow-duration="1s"
          >
            <div class="transport-card text-center">
              <div class="transport-text">
                <i class="icofont-presentation" /><br><br>
                <h6> Envoi de l'offre aux transporteurs proches </h6>
                <!--<p>Publier vos différentes offres de transport et laisser eBDFRET  vous mettre  en contacte avec les meilleurs transporteurs </p>
-->
                <a href="service-details.html" />
              </div>
            </div>
          </div>

          <div
            class="col-lg-2 col-sm-6 wow fadeInUp"
            data-wow-duration="1s"
            data-wow-delay=".3s"
          >
            <div class="transport-card text-center">
              <div class="transport-text">
                <i class="icofont-contact-add" /><br><br>
                <h6>Réception de propositions de devis</h6>
                <!--   <p>Recevez rapidement des proposotions devis  et validez le devis qui vous convient</p><br>
-->
                <a href="service-details.html" />
              </div>
            </div>
          </div>

          <div
            class="col-lg-2 col-sm-6 offset-lg-0 wow fadeInUp"
            data-wow-duration="1s"
            data-wow-delay=".6s"
          >
            <div class="transport-card text-center">
              <div class="transport-text">
                <i class="icofont-law-document" /><br><br>
                <h6>Elaboration de Contrat avec le transporteur</h6>
                <!--   <p>A cargo aircraft also known as freight aircraft, freighter, airlifter or cargo jet is a fixed-wing aircraft that is designed.</p>
-->
                <a href="ocean-freight.html" />
              </div>
            </div>
          </div>

          <div
            class="col-lg-2 col-sm-6 offset-lg-0 wow fadeInUp"
            data-wow-duration="1s"
            data-wow-delay=".6s"
          >
            <div class="transport-card text-center">
              <div class="transport-text">
                <i class="icofont-document-folder" /><br><br>
                <h6> Envoi immédiat de la preuve de livraison</h6>
                <!--   <p>A cargo aircraft also known as freight aircraft, freighter, airlifter or cargo jet is a fixed-wing aircraft that is designed.</p>
-->
                <a href="ocean-freight.html" />
              </div>
            </div>
          </div>

          <div
            class="col-lg-2 col-sm-6  offset-lg-0 wow fadeInUp"
            data-wow-duration="1s"
            data-wow-delay=".6s"
          >
            <div class="transport-card text-center">
              <div class="transport-text">
                <i class="icofont-checked" /><br><br>
                <h6>Facture et notation du transporteur</h6>
                <!--   <p>A cargo aircraft also known as freight aircraft, freighter, airlifter or cargo jet is a fixed-wing aircraft that is designed.</p>
-->
                <a href="ocean-freight.html" />
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>

  </div>
</template>
<script>
export default {

}
</script>
<style >

</style>
