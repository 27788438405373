<template>
  <div>
    <PageTitle /><br><br>

    <How />
    <!-- Why Choose Section Start -->
    <div class="choose-style-two choose-style-three pb-4">
      <div class="container">
        <div class="section-title text-center">
          <h2>eBDFRET vous propose des transporteurs dynamiques
            et rapides. Testez nos services et notez-nous</h2>
          <p>.</p>

        </div>

        <div class="row align-items-center">
          <div class="col-lg-3">
            <div class="row">
              <div
                class="col-lg-12 col-sm-4 wow fadeInUp"
                data-wow-duration="1s"
              >
                <div class="choose-card">
                  <i class="icofont-fast-delivery" />
                  <h3>Livraison rapide et sûre</h3>
                </div>
              </div>
              <div
                class="col-lg-12 col-sm-4 wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay=".2s"
              >
                <div class="choose-card">
                  <i class="icofont-ssl-security" />
                  <h3>Sécurité des produits</h3>
                </div>
              </div>
              <div
                class="col-lg-12 col-sm-4 wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay=".4s"
              >
                <div class="choose-card">
                  <i class="icofont-diamond" />
                  <h3>Prix abordable</h3>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="why-choose-image">
              <img
                src="@/assets/images/trans.jpg"
                alt="why Choose image"
              >
            </div>
          </div>

          <div class="col-lg-3">
            <div class="row">
              <div
                class="col-lg-12 col-sm-4 wow fadeInUp"
                data-wow-duration="1s"
              >
                <div class="choose-card">
                  <i class="icofont-ui-browser" />
                  <h3>Paiement sécurisé</h3>
                </div>
              </div>
              <div
                class="col-lg-12 col-sm-4 wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay=".2s"
              >
                <div class="choose-card">
                  <i class="icofont-live-support" />
                  <h3>Assistance 24/7</h3>
                </div>
              </div>
              <div
                class="col-lg-12 col-sm-4 wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay=".4s"
              >
                <div class="choose-card">
                  <i class="icofont-like" />
                  <h3>Bien expérimenté</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Why Choose Section End -->
    <TrustedCustomers />
  </div>

</template>

<script>
import PageTitle from '@/layouts/SiteLayout/espace_affreteur/PageTitle.vue'
import How from '@/layouts/SiteLayout/espace_affreteur/How.vue'
import TrustedCustomers from '@/layouts/SiteLayout/home/TrustedCustomers.vue'

export default {
  components: {
    PageTitle, How, TrustedCustomers,

  },
}
</script>
