<template>

  <div>

    <!-- Page Title Start -->
    <div class="page-title title-bg-10">
      <div class="container">
        <div class="title-text text-center">
          <h2>Pourquoi  choisir eBDFRET</h2>
          <div class="">
            <div class="container-fluid">
              <div class="track-text text-center">
                <h4 style="color: white;">
                  Trouvez un transporteur en un clic!
                </h4>

                <form
                  data-toggle="validator"
                  class="recherche"
                >
                  <div class="row">
                    <div class="col-lg-3">
                      <input
                        type="search"
                        class="form-control"
                        placeholder="Enlèvement (cp ou ville)"
                        name="EMAIL"
                        required
                        autocomplete="off"
                      >
                    </div>
                    <div class="col-lg-3">
                      <input
                        type="search"
                        class="form-control"
                        placeholder="Livraison (cp ou ville)"
                        name="EMAIL"
                        required
                        autocomplete="off"
                      >
                    </div>
                    <div class="col-lg-4"><input
                                            variant="primary"
                                            type="search"
                                            class="form-control"
                                            placeholder="Marchandise à expédier"
                                            name="EMAIL"
                                            required
                                            autocomplete="off"
                                          >
                      <div>

                        <b-collapse
                          id="collapse-1"
                          class="mt-2"
                          style="position:absolute; z-index:2;"
                        >
                          <b-card>
                            <div
                              class="select-item"
                              style="width:300px;"
                            >
                              <select class="form-control">
                                <option selected>
                                  De quel type ?
                                </option>
                                <option>Alimentaire</option>
                                <option>Autres</option>
                              </select>
                            </div>
                            <div class="select-item">
                              <select class="form-control">
                                <option selected>
                                  Longeur
                                </option>
                                <option>1</option>
                                <option>2</option>
                                <option>4</option>
                                <option>6</option>

                              </select>
                            </div>
                            <div class="select-item">
                              <select class="form-control">
                                <option selected>
                                  Largeur
                                </option>
                                <option>1</option>
                                <option>2</option>
                                <option>4</option>
                                <option>6</option>

                              </select>
                            </div>
                            <input
                              type="number"
                              class="form-control"
                              placeholder="Hauteur"
                              name="EMAIL"
                              required
                              autocomplete="off"
                            >

                            <input
                              type="number"
                              class="form-control"
                              placeholder="Poids"
                              name="EMAIL"
                              required
                              autocomplete="off"
                            >

                            <b-collapse
                              id="collapse-1-inner"
                              class="mt-2"
                            >
                              <b-card>Hello!</b-card>
                            </b-collapse>
                          </b-card>
                        </b-collapse>
                      </div>
                    </div>

                    <div class="col-lg-2">
                      <button
                        class="default-btn electronics-btn"
                        type="submit"
                      >
                        TARIF DIRECT
                      </button>
                    </div>
                  </div>

                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Page Title End -->
  </div>
</template>
<script>
import {
  BCollapse, BCard,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCollapse,
  },
  data() {
    return {
      types: [

        'search',

      ],
    }
  },
}
</script>
<style>
.recherche
{
position: relative;

}
.recherche .form-control
{
  height: 50px;
  border-radius: 5px;
  border: 1px solid #F26A3D;
}
.recherche .default-btn {
  font-size: 17px;
  background: #F26A3D;
  color: #ffffff;
  padding: 5px 15px;
  border-radius: 5px;
  margin: 0;
  display: inline-block;
  position: absolute;
 z-index: 0;
  overflow: hidden;
  right: 4px;
  top: 4px;
  border: 1px solid #F26A3D;
}

.recherche .default-btn:hover {
  color: #F26A3D;
  background: #ffffff;
}
.track-product .track-text h4 {
  font-size: 35px;
  font-weight: 700;
  color: #ffffff;
  margin-bottom: 30px;
}

</style>
