<template>
  <!-- Achivement Section Start -->
  <section class="achivement-section achivement-bg pt-4 pb-4">
    <div class="container">
      <div class="section-title text-center py-2">
        <h2>Ils nous ont fait confiance</h2>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <div class="company-section">
            <div class="container">
              <carousel
                pagination-position="bottom-overlay"
                :pagination-color="'#efefef'"
                :scroll-per-page="false"
                :navigation-enabled="true"
                :autoplay-timeout="3000"
                :autoplay="true"
                :per-page="5"
                :loop="true"
                :mouse-drag="true"
                class="company-slider owl-carousel owl-theme"
              >
                <Slide class="company-logo px-4">
                  <a href="#">
                    <img
                      src="@/assets/images/logomameri.png"
                      alt="logo"
                    >
                  </a>
                </Slide>
                <Slide class="company-logo px-4">
                  <a href="#">
                    <img
                      src="@/assets/images/logomameri.png"
                      alt="logo"
                    >
                  </a>
                </Slide>
                <Slide class="company-logo px-4">
                  <a href="#">
                    <img
                      src="@/assets/images/logomameri.png"
                      alt="logo"
                    >
                  </a>
                </Slide>
                <Slide class="company-logo px-4">
                  <a href="#">
                    <img
                      src="@/assets/images/logomameri.png"
                      alt="logo"
                    >
                  </a>
                </Slide>
                <Slide class="company-logo px-4">
                  <a href="#">
                    <img
                      src="@/assets/images/logomameri.png"
                      alt="logo"
                    >
                  </a>
                </Slide>
                <Slide class="company-logo px-4">
                  <a href="#">
                    <img
                      src="@/assets/images/logomameri.png"
                      alt="logo"
                    >
                  </a>
                </Slide>
                <Slide class="company-logo px-4">
                  <a href="#">
                    <img
                      src="@/assets/images/logomameri.png"
                      alt="logo"
                    >
                  </a>
                </Slide>
                <Slide class="company-logo px-4">
                  <a href="#">
                    <img
                      src="@/assets/images/logomameri.png"
                      alt="logo"
                    >
                  </a>
                </Slide>
                <Slide class="company-logo px-4">
                  <a href="#">
                    <img
                      src="@/assets/images/logomameri.png"
                      alt="logo"
                    >
                  </a>
                </Slide>
                <Slide class="company-logo px-4">
                  <a href="#">
                    <img
                      src="@/assets/images/logomameri.png"
                      alt="logo"
                    >
                  </a>
                </Slide>
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Achivement Section End -->
</template>
<script>
import { Carousel, Slide } from 'vue-carousel'

export default {
  name: 'Banner',
  components: {
    Carousel, Slide,
  },
}
</script>
